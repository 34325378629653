#home-collections {
  .page-title {
    font-size: xx-large;
    margin-top: 50px;
    font-weight: 500;
  }
  .collection-list {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
  }

  .no-collections {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 70px;

    .message-title {
      font-size: x-large;
    }

    .box-container {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;

      .box {
        padding: 10px;
        min-height: 100px;
        width: 300px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        border: 1px solid $light-gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid $dark;

        &:hover {
          background-color: $light-gray;
        }

        .box-title {
          font-weight: 500;
        }

        .box-text {
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  .page {
    margin: 50px auto;
    display: flex;
    gap: 15px;
    align-items: center;
    width: max-content;

    .chevron {
      padding: 5px;
      height: 30px;
      cursor: pointer;

      &.disabled {
        cursor: unset;
        opacity: 0.3;
      }
    }
  }
}
