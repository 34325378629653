a {
  font-size: $font-size-primary;

  text-decoration: none;
  color: unset;

  position: relative;

  height: fit-content;
  width: fit-content;

  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0px;
    background-color: $black;
    bottom: -2px;
    left: 0.8px;

    transition-duration: 0.3s;
  }

  &:hover {
    color: unset;

    &::after {
      width: 100%;
    }
  }

  &.light {
    color: $light;

    &::after {
      background-color: $light;
    }
  }
}
