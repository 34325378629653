// COLORS

$background: #f5f5f5;
$black: #1c1c1c;
$white: #fff;
$muted: #808080;
$disabled: #d7d7d7;
$light-gray: #e6e6e6;

$accent: #d5b9b2ff;
$light: #0b22876a;
$secondary: #7171f6;
$primary: #0b2287;
$dark: #161d37;
$error: #e63946;
$bright-dark: #222d55;

$gold: #c7a900;

// OTHERS

$border-radius: 5px;
$box-shadow: 0px 0px 24px rgba(150, 150, 150, 0.4);
