#apikey-create-modal {
  padding: 0 15px 15px 15px;
  align-items: center;
  gap: 15px;

  form {
    gap: 10px;

    .input {
      flex-direction: column;
      display: flex;
      width: 100%;

      input {
        margin: 0;
      }
    }

    .button {
      display: flex;
      align-items: flex-end;
    }

    display: flex;
    flex-direction: row;
  }
}

#delete-apikey-modal {
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;

    .cancel {
      width: 25%;
    }
    .confirm {
      width: 25%;
    }
  }
}

#collection-alias-modal {
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;
  }
}

#payment-method-modal {
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;
  }
}
