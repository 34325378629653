#collection-images {
  .img-preview-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;

    .img-preview {
      position: relative;
      .delete {
        position: absolute;
        top: 0px;
        right: 00px;

        display: flex;
        align-items: center;
        justify-content: center;

        $dim: 25px;
        width: $dim;
        height: $dim;
        min-width: $dim;

        background-color: white;
        padding: 5px;
        border-radius: 100%;
      }

      .image {
        border-radius: $border-radius;
        margin: 10px 10px 0 0;
        height: 150px;
        width: 150px;
      }
    }
  }
}
