#collection-page {
  display: flex;
  flex-direction: row;

  .content {
    width: 100%;
    padding: 25px;
  }

  .side {
    min-height: 80vh;
    background-color: $dark;
    padding: 20px 0;

    border-top: 1px solid $light-gray;

    .collection-title {
      color: $white;
      width: 100%;
      text-align: center;
      font-size: large;
      font-weight: 400;
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }

    .menu-item {
      color: $light-gray;
      display: flex;
      align-items: center;
      gap: 20px;
      cursor: pointer;

      padding: 5px 25px;

      @include media-breakpoint-down(xl) {
        padding: 5px 15px;
      }

      border-radius: $border-radius;
      margin: 0 5px;
      font-weight: 400;

      white-space: nowrap;

      .menu-item-text {
        @include media-breakpoint-down(xl) {
          display: none;
        }
      }

      &.selected {
        color: $secondary;
      }

      .menu-icon {
        filter: invert(1);
        opacity: 0.6;
        height: 20px;
        width: 20px;
        margin-top: 2px;
      }

      .menu-icon-appear {
        filter: invert(1);
        margin-left: auto;
        opacity: 0;
        height: 15px;

        @include media-breakpoint-down(xl) {
          display: none;
        }
      }

      &:hover:not(.disabled) {
        background-color: $bright-dark;

        .menu-icon-appear {
          opacity: 1;
        }
      }

      &.disabled {
        cursor: default;
        opacity: 0.2;
      }

      &.selected {
        font-weight: 500;

        .menu-icon {
          opacity: 1;
        }
      }
    }

    .hr {
      opacity: 0.3;
      margin: 10px 0;
    }
  }

  .collection-sub-page {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-top: 25px;

    .hr {
      margin: 20px 0;
    }

    .title {
      font-weight: 500;
    }

    .members {
      border: 1px solid $light-gray;
      border-radius: $border-radius;

      .member {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-bottom: 1px solid $light-gray;
        position: relative;

        .settings {
          position: absolute;
          top: 5px;
          right: 5px;

          display: none;
        }

        &:hover {
          background-color: $light-gray;

          .settings {
            display: block;
          }
        }

        .invite-pending {
          color: $red;
        }
      }
    }
  }

  .page {
    margin: 50px auto;
    display: flex;
    gap: 15px;
    align-items: center;

    .chevron {
      padding: 5px;
      height: 30px;
      cursor: pointer;

      &.disabled {
        cursor: unset;
        opacity: 0.3;
      }
    }
  }

  .search-nft {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .icon {
      height: 25px;
      width: 25px;

      margin: 0 0 0 -35px;
      opacity: 0.8;
    }
  }

  .view-icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
}
