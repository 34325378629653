#create-collection {
  position: fixed;
  height: 100vh;
  width: 150vw;

  max-height: 100vh;
  overflow-y: scroll;

  top: 0;

  &.opened {
    left: 0;
  }

  &.closed {
    left: 150vw;
  }

  transition-duration: 0.4s;

  background-color: $background;
  box-shadow: $box-shadow;

  z-index: 99999;

  padding: 50px;

  .header {
    display: flex;
    align-items: center;
    .title {
      font-size: larger;
    }
    .close {
      cursor: pointer;

      img {
        padding: 10px;
        height: 50px;
        width: 50px;
      }
    }
  }

  .steps {
    margin-top: 100px;
    max-width: 650px;

    .title {
      font-size: xx-large;
      font-weight: 500;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .previous {
        cursor: pointer;
        border-radius: $border-radius;
        padding: 5px;
        height: max-content;
        color: $dark;
        font-weight: 500;

        &:hover {
          background-color: $light-gray;
        }
      }
    }

    .collection-types {
      margin-top: 25px;
      display: flex;
      gap: 15px;

      .collection-type {
        padding: 20px;
        border-radius: $border-radius;
        border: 1px solid $light-gray;
        box-shadow: $box-shadow;
        cursor: pointer;

        width: 250px;

        &:hover {
          background-color: $light-gray;
        }

        .collection-type-title {
          font-size: large;
          font-weight: 500;
        }

        &.selected {
          border: 2px solid $dark;
        }

        &.gold {
          .collection-type-title {
            color: $gold;
          }
        }
      }
    }

    .collection-info {
      .collection-form-name {
        font-size: xx-large;
        padding: 30px;
        width: 100%;
      }
    }

    .payment-method {
      .payment-method-select-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .payment-method-select {
          width: 50%;
        }
      }
    }
  }
}
