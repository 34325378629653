#group-page {
  .head-container {
    border-bottom: 1px solid $light;

    .head {
      margin: 25px 0;
      display: flex;
      gap: 20px;
      align-items: center;

      .group-logo {
        height: 50px;
      }

      .title {
        font-weight: 500;
        font-size: larger;
      }

      .not-verified {
        color: $red;
      }
    }
    .head-menu {
      display: flex;
      gap: 35px;
      margin-bottom: 5px;

      .menu-item {
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        border-radius: $border-radius;
        position: relative;

        .icon {
          height: 20px;
          width: 20px;
          margin-bottom: 4px;
        }

        &:hover {
          background-color: $light-gray;
        }

        &.selected {
          font-weight: 500;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -5px;

            background-color: $primary;
            height: 2px;
          }
        }
      }
    }
  }

  .group-sub-page {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-top: 25px;

    .hr {
      margin: 20px 0;
    }

    .title {
      font-weight: 500;
    }

    .members {
      border: 1px solid $light-gray;
      border-radius: $border-radius;

      .member {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-bottom: 1px solid $light-gray;
        position: relative;

        .settings {
          position: absolute;
          top: 5px;
          right: 5px;

          display: none;
        }

        &:hover {
          background-color: $light-gray;

          .settings {
            display: block;
          }
        }

        .invite-pending {
          color: $red;
        }
      }
    }

    .group-collection-header {
      display: flex;
      justify-content: space-between;
    }

    .page {
      margin: 50px auto;
      display: flex;
      gap: 15px;
      align-items: center;

      .chevron {
        padding: 5px;
        height: 30px;
        cursor: pointer;

        &.disabled {
          cursor: unset;
          opacity: 0.3;
        }
      }
    }

    .collection-list {
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
