table {
  width: 100%;

  font-size: small;

  th,
  td {
    padding: 10px;
  }

  thead {
    background-color: $dark;
    color: $white;
    border-radius: $border-radius;

    th {
      font-weight: normal;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: $light-gray;
      }
    }
  }
}
