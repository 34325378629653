.collection-card {
  margin: 10px;
  padding: 20px;
  border: 1px solid $light-gray;
  box-shadow: $box-shadow;
  border-radius: $border-radius;

  width: 300px;
  height: 200px;

  position: relative;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  &:hover {
    background-color: $light-gray;
  }

  .title {
    font-weight: 500;
    font-size: large;
  }

  .nft {
    .number {
      font-size: 100px;
      font-weight: 500;
      color: $secondary;
    }
  }

  &.gold {
    .title {
      color: $gold;
    }
  }

  .buttons {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    gap: 10px;
  }
}
