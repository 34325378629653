#modal-container {
  position: fixed;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.3);

  padding: 10px;

  .modal-content {
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow;

    position: relative;

    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: scroll;

    padding-top: 50px;

    .close-modal {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 10px;

      height: 50px;
      width: 50px;

      cursor: pointer;
    }
  }
}
