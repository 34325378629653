#user-dropdown {
  $padding: 15px;
  color: $black;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-width: 400px;

  max-height: 80vh;
  overflow: scroll;

  p {
    margin: 0;
  }

  .block {
    padding: $padding;
    width: 100%;
    display: flex;
    flex-direction: column;

    .email {
      color: $muted;
      text-align: center;
    }

    .avatar {
      margin: 0 auto 15px auto;
      height: 50px;
      width: 50px;
    }
  }
  .group-title {
    width: 100%;
    padding: 0px $padding;
    margin-top: $padding;
    font-weight: bold;
  }

  .payment {
    padding: $padding;
    margin: 10px 0;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: $light-gray;
    }
  }

  .group {
    padding: 10px $padding;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background-color: $light-gray;
    }

    .not-verified {
      color: $error;
    }
  }

  .create-group {
    width: 100%;
    margin: 15px 0;
    padding: 10px $padding;
    color: $muted;

    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: $light-gray;
    }

    img {
      margin-top: 2px;
      height: 15px;
      width: 15px;
    }
  }
}
