.custom-checkbox {
  margin-top: 3px;
  .switch {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 50px;
  }

  .switch input {
    display: none;
  }

  .slider {
    background-color: $light; // bg not selected
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }

  .slider:before {
    background-color: $dark; // bal not selected
    bottom: 2px;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    position: absolute;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $dark; // bg selected

    &::before {
      background-color: $white; // ball selected
    }
  }

  input:checked + .slider:before {
    transform: translateX(24px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
