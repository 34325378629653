*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
html,
#root {
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

/* main {
  margin-bottom: 150px;
} */

footer {
  margin-top: auto;
}

.nav-margin {
  height: 100px;
}
