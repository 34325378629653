#nft-detail-modal {
  padding: 0 25px 25px 25px;

  max-height: 80vh;
  overflow: scroll;

  > div {
    display: flex;
    gap: 10px;
  }

  p {
    margin: 0;

    &.title {
      margin-top: 10px;
      font-weight: 500;
    }
  }
}
